<template>
  <SiteLayout :showHeaderFooter="!isEmbedded">
    <div id="accounts_show" class="accounts_show">
      <header
        class="user-research-library-company-header d-flex"
        @click.stop="hideEviDropdown"
        :style="cssVars"
      >
        <div class="header-wrapper d-flex justify-content-center">
          <div
            v-if="account"
            class="header-text-container d-flex align-items-center"
            :class="{ 'header-text-container--embedded': isEmbedded }"
          >
            <div :class="{ 'dark-text': useDarkText }">
              <div v-if="account.svg_logo" class="logo" v-html="account.svg_logo"></div>
              <h1 v-else>{{ account.name }}</h1>
              <span class="uerc-text">Research Library</span>
            </div>
          </div>

          <div class="search-wrapper d-flex flex-column justify-content-center">
            <div class="search-box">
              <div class="search-icon">
                <b-icon-search></b-icon-search>
              </div>
              <BFormInput
                id="search-box-id"
                data-testid="search-box-id"
                @click.stop="showEviDropdown"
                v-model="q"
                placeholder="Type to filter by keyword"
                debounce="500"
              >
              </BFormInput>
              <div v-if="q.length > 0" class="search-clear-icon" @click="q = ''">
                <b-icon-x></b-icon-x>
              </div>
            </div>

            <div class="evi-dropdown d-flex">
              <div class="text-wrapper d-flex justify-content-between" v-if="eviEnabled">
                <span class="d-flex align-items-center"
                  ><strong>Want to ask a question ?&nbsp;</strong
                  ><span>Try our new AI chat feature</span></span
                >
                <button @click="openEvi" class="ask-evi-button border d-flex">
                  <div class="d-flex align-items-center icon" v-html="aiMagic" />
                  <strong>Ask Evi</strong>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main>
        <section class="uerl_filters">
          <BFormSelect v-model="type" :options="typeFilter"></BFormSelect>
          <BFormSelect
            v-model="sector_id"
            :options="the_sector_filters"
            :disabled="sector_filters && sector_filters.length == 0"
          ></BFormSelect>
          <BFormSelect
            v-model="size_group"
            :options="the_size_group_filters"
            :disabled="size_group_filters && size_group_filters.length == 0"
          ></BFormSelect>
          <BFormSelect
            v-model="seniority"
            :options="the_seniority_filters"
            :disabled="seniority_filters && seniority_filters.length == 0"
          ></BFormSelect>
          <BFormSelect
            v-model="recipient_role_id"
            :options="the_recipient_role_filters"
            :disabled="recipient_role_filters && recipient_role_filters.length == 0"
          ></BFormSelect>
        </section>
        <section ref="asset-container" v-if="initialized && account" class="asset_container">
          <div
            v-if="account && content_assets && content_assets.length > 0"
            class="assets"
            :class="{ few: few }"
          >
            <div v-for="asset in content_assets" :key="asset.id" class="asset">
              <router-link v-if="!isEmbedded" :to="assetUrl(asset)">
                <TestimonialBasic
                  v-if="asset.type == 'RenderableTestimonial'"
                  :renderable-testimonial="asset"
                  :account="account"
                  renderAsList
                ></TestimonialBasic>
                <BarChartBasic
                  v-else-if="asset.type == 'ChartAsset'"
                  :content-asset="theAsset(asset)"
                  :account="account"
                  renderAsList
                ></BarChartBasic>
                <StatAsset
                  v-else-if="asset.type == 'StatAsset'"
                  :content-asset="theAsset(asset)"
                  :account="account"
                  renderAsList
                ></StatAsset>
                <Microsite v-else-if="asset.type == 'AssetList'" :asset_list="asset"></Microsite>
                <SpotlightPreview
                  v-else-if="['CustomerSpotlight', 'SurveySpotlightAsset'].includes(asset.type)"
                  :content_asset="theAsset(asset)"
                  :customer_spotlight="asset"
                  :account="account"
                ></SpotlightPreview>
                <MatrixxChartBasic
                  v-else-if="asset.type == 'MatrixxChart'"
                  class="as_list"
                  :matrixxChart="theAsset(asset)"
                  :account="account"
                  renderAsList
                ></MatrixxChartBasic>
                <VideoLinkAsset
                  v-else-if="asset.type == 'VideoLinkAsset'"
                  :video-link-asset="theAsset(asset)"
                  :account="account"
                  renderAsList
                ></VideoLinkAsset>
                <FileAsset
                  v-else-if="asset.type == 'FileAsset'"
                  class="as_list"
                  :file-asset="theAsset(asset)"
                  :account="account"
                ></FileAsset>
              </router-link>
              <div v-else>
                <a :href="assetUrl(asset)" target="_blank">
                  <TestimonialBasic
                    v-if="asset.type == 'RenderableTestimonial'"
                    :renderable-testimonial="asset"
                    :account="account"
                  ></TestimonialBasic>
                  <BarChartBasic
                    v-else-if="asset.type == 'ChartAsset'"
                    :content-asset="theAsset(asset)"
                    :account="account"
                  ></BarChartBasic>
                  <StatAsset
                    v-else-if="asset.type == 'StatAsset'"
                    :content-asset="theAsset(asset)"
                    :account="account"
                  ></StatAsset>
                  <Microsite v-else-if="asset.type == 'AssetList'" :asset_list="asset"></Microsite>
                  <SpotlightPreview
                    v-else-if="['CustomerSpotlight', 'SurveySpotlightAsset'].includes(asset.type)"
                    :content_asset="theAsset(asset)"
                  ></SpotlightPreview>
                  <MatrixxChartBasic
                    v-else-if="asset.type == 'MatrixxChart'"
                    :matrixxChart="theAsset(asset)"
                    :account="account"
                  ></MatrixxChartBasic>
                  <VideoLinkAsset
                    v-else-if="asset.type == 'VideoLinkAsset'"
                    :video-link-asset="theAsset(asset)"
                    renderAsList
                    :account="account"
                  ></VideoLinkAsset>
                  <FileAsset
                    v-else-if="asset.type == 'FileAsset'"
                    renderAsList
                    :file-asset="theAsset(asset)"
                    :account="account"
                  ></FileAsset>
                </a>
              </div>
            </div>
          </div>
          <div v-else class="assets none">
            <h4>No {{ typeString }} Yet</h4>
            <p>
              We're currently collecting survey results and will have content assets ready soon.
            </p>
          </div>
          <Pagination
            v-if="content_assets && content_assets.length > 0"
            v-model="page"
            :records="total_count"
            :per-page="25"
          ></Pagination>
        </section>
      </main>
    </div>
    <Evi></Evi>
  </SiteLayout>
</template>

<script lang="ts">
import axios from '@lib/apiClient'
import TestimonialBasic from '@/components/TestimonialBasic.vue'
import MatrixxChartBasic from '@/components/MatrixxChartBasic.vue'
import BarChartBasic from '@/components/BarChartBasic.vue'
import StatAsset from '@/components/StatBasic.vue'
import Microsite from '@/app/components/Microsite.vue'
import SpotlightPreview from '@/components/SpotlightPreview.vue'
import Pagination from 'vue-pagination-2'
import filters from '@mixins/filters.js'
import url_manager from '@mixins/url_manager'
import find from 'lodash/find'
import { gradientColors, useDarkText } from '@lib/colors'
import { useAccountStore } from '@app/stores/AccountStore'
import ahoy from 'ahoy.js'
import VideoLinkAsset from '@/components/VideoLinkAsset.vue'
import FileAsset from '@/components/FileAsset.vue'
import SiteLayout from '@site/components/SiteLayout.vue'
import Evi from '@site/components/chatbot/Evi.vue'
import aiMagicIcon from '@images/icons/ai-magic.svg?raw'

export default {
  components: {
    SiteLayout,
    TestimonialBasic,
    BarChartBasic,
    StatAsset,
    Microsite,
    SpotlightPreview,
    Pagination,
    MatrixxChartBasic,
    VideoLinkAsset,
    FileAsset,
    Evi,
  },
  props: {
    isEmbedded: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [filters, url_manager],
  data() {
    return {
      account: null,
      content_assets: [], // this list can contain RenderableTestimonials too
      count: null,
      total_count: null,
      typeFilter: [
        { value: '', text: 'All Asset Types' },
        { value: 'ChartAsset', text: 'Charts' },
        { value: 'CustomerSpotlight', text: 'Customer Spotlights' },
        { value: 'RenderableTestimonial', text: 'Testimonials' },
        { value: 'StatAsset', text: 'Stats' },
        { value: 'SpotlightAsset', text: 'Reports' },
        { value: 'MatrixxChart', text: 'Matrix Charts' },
        { value: 'AssetList', text: 'Microsites' },
        { value: 'VideoLinkAsset', text: 'Video Link Assets' },
        { value: 'FileAsset', text: 'File Assets' },
      ],
    }
  },
  head() {
    return {
      title: `${this.account.name} UserEvidence Research Library | UserEvidence`,
      meta: [{ hid: 'theme-color', name: 'theme-color', content: this.account?.brand_color_1 }],
    }
  },
  computed: {
    eviEnabled() {
      return this.account?.flipper_features.includes('evi')
    },
    aiMagic() {
      return aiMagicIcon
    },
    few() {
      return this.content_assets?.length < 5
    },
    cssVars() {
      const [startColor, endColor] = this.gradientColors
      return {
        '--header-gradient': `linear-gradient(${startColor}, ${endColor})`,
      }
    },
    typeString() {
      const defaultStr = 'Content Assets'
      if (this.type?.length === 0) return defaultStr

      // Find proper type string text from typeFilter constant list
      const type = find(this.typeFilter, (t) => t.value == this.type)
      return type?.text
    },
    asset_url() {
      return `/api/accounts/${this.$route.params.id}/content_assets.json`
    },
    filterUrl() {
      return `/api/accounts/${this.$route.params.id}/asset_filters.json`
    },
    gradientColors,
    useDarkText,
  },
  watch: {
    async q() {
      // Reset the page back to the first when making a text search
      this.page = 1
      await this.getContentAssets()
      await this.scrollToAssets()
    },
    async page() {
      await this.getContentAssets()
      await this.scrollToAssets()
    },
    type() {
      this.getContentAssets()
    },
    size_group() {
      this.getContentAssets()
    },
    sector_id() {
      this.getContentAssets()
    },
    seniority() {
      this.getContentAssets()
    },
    recipient_role_id() {
      this.getContentAssets()
    },
  },
  mounted() {
    axios
      .get(`/api/accounts/${this.$route.params.id}.json`)
      .then((r) => (this.account = r.data.account))
    this.sort_by = 'updated_at'
    this.sort_dir = 'desc'
    this.getContentAssets()

    // we are doing this here to get the app json for koala tracking.
    // note this request will fail if the user is not logged in via the app.
    // be aware that we'll be seeing 401's in the logs because of this
    useAccountStore().getApp()

    ahoy.trackView()
    window.addEventListener('resize', this.sendHeightToParent)
  },
  updated() {
    this.sendHeightToParent()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.sendHeightToParent)
  },
  methods: {
    async scrollToAssets() {
      // do not scroll to results when in embedded view
      if (!this.isEmbedded) {
        this.$nextTick(() => {
          window.scroll({
            top: 75, // top header height
            behavior: 'smooth',
          })
        })
      }
    },
    hideEviDropdown() {
      const searchBox = document.querySelector('.evi-dropdown')
      const searchInput = document.querySelector('#search-box-id')
      searchBox.style.height = '1px'
      searchBox.style.width = '570px'
      searchBox.style.background = 'transparent'
      searchBox.style['margin-top'] = 'unset'
      searchBox.style['margin-left'] = 'unset'
      searchInput.style.background = 'white'
      searchInput.style.border = '1px solid transparent'
    },
    showEviDropdown() {
      // only shows the dropdown if the feature is enabled
      // and we are not in an embedded view
      if (this.eviEnabled) {
        const searchBox = document.querySelector('.evi-dropdown')
        const searchInput = document.querySelector('#search-box-id')
        searchBox.style.height = '165px'
        searchBox.style.width = '620px'
        searchBox.style.background = 'white'
        searchBox.style['margin-top'] = '75px'
        searchBox.style['margin-left'] = '-18px'
        searchInput.style.background = '#f5f5f5'
        searchInput.style.border = '1px solid #CED4DA'
      }
    },
    openEvi() {
      this.$modal.show('evi_modal', { account: this.account, isEmbedded: this.isEmbedded })
    },
    theAsset(asset) {
      return Object.assign(asset, { account: this.account })
    },
    assetUrl(asset) {
      return asset.type == 'AssetList' ? `/sites/${asset.slug}` : `/assets/${asset.identifier}`
    },
    async getContentAssets() {
      return await Promise.all([
        axios.get(this.asset_url, { params: this.the_query }),
        axios.get(this.filterUrl, { params: this.the_query }),
      ]).then((r) => {
        this.content_assets = r[0].data.content_assets
        this.count = r[0].data.count
        this.total_count = r[0].data.total_count
        this.size_group_filters = r[1].data.size_group_filters
        this.sector_filters = r[1].data.sector_filters
        this.seniority_filters = r[1].data.seniority_filters
        this.recipient_role_filters = r[1].data.recipient_role_filters
        this.initialized = true
      })
    },
    sendHeightToParent() {
      const height = document.body.scrollHeight
      window.parent.postMessage({ height: height }, '*')
    },
  },
}
</script>
<style lang="sass" scoped>

header
  background: url('../../../../images/home/ue-email-signup-background-shape.svg'), var(--header-gradient) !important
  background-repeat: no-repeat

.search-icon
  position: absolute
  z-index: 10
  margin-top: 12px
  margin-left: 1.3rem
  svg
    color: black
    width: 1rem
    height: 1rem

.search-clear-icon
  position: absolute
  z-index: 10
  margin-top: -42px
  margin-left: 535px
  cursor: pointer
  svg
    color: black
    width: 1rem
    height: 1rem

.accounts_show

  .header-wrapper
    width: 100%

  .user-research-library-company-header
    width: 100%
    height: 370px

    .evi-dropdown
      // The trick here is that this component
      // is hidden behind the search input, and expands when on click
      overflow: hidden
      z-index: 1
      margin-left: 1px
      position: absolute
      transition: all 0.4s
      height: 1px
      width: 570px // this width should match the search box width
      border-radius: 44px
      background: transparent
      .text-wrapper
        width: 100%
        font-size: 0.7rem
        padding-left: 3rem
        padding-right: 3rem
        margin-top: auto
        margin-bottom: 5px
        padding-bottom: 0.5rem

      .ask-evi-button
        align-items: center
        background: white
        padding: 0.5rem
        border-radius: 0.3rem
        svg
          color: $uePurple

    .search-wrapper
      padding-left: 1rem
    .search-box
      transition: all 0.3s
      z-index: 2
      width: 580px
      input
        min-height: 55px
        border: 1px solid transparent
        border-radius: 48px
        font-size: 0.8rem
        padding-left: 3rem
        &:focus, &:hover
          box-shadow: none
          outline-style: hidden
          outline-style: none
        &::placeholder
          font-size: 0.7rem

  .asset_container
    width: 100%
    padding-left: 64px
    padding-right: 64px
    padding-bottom: 64px
    position: relative

  .uerl_filters
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    background-color: white
    position: sticky
    padding: 8px 64px
    select
      text-transform: capitalize
      -moz-appearance: none
      -webkit-appearance: none
      appearance: none
      background-color: white
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cg fill='none'%3E%3Cpath d='M10 4L6 8 2 4' stroke='%23899EA9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3C/svg%3E")
      background-size: 12px 12px
      background-position: calc(100% - 12px) 50%
      background-repeat: no-repeat
    input, select
      background-color: white
      border-radius: 12px
      border: 1px solid hsl(200, 24%, 88%)
      color: hsl(200, 12%, 40%)
      font-weight: 500
      line-height: 1
      font-size: 12px
      &:not(:last-child)
        margin-right: 2%
      &:focus
        outline: 0 !important
        box-shadow: 0 0 0 2px hsl(270, 100%, 56%)
        &:hover
          box-shadow: 0 0 0 2px hsl(270, 100%, 56%)
      &:hover
        background-color: white
        box-shadow: 0 0 0 2px hsl(270, 100%, 88%)
      &:focus, &:hover
        border: 1px solid hsl(270, 100%, 92%)
    select
      padding: 16px 36px 16px 12px

  .none
    columns: 1 !important
    width: 100%
    max-width: 1056px
    padding-left: 0
    padding-right: 0
    margin: 0 auto
    p
      margin-top: 12px

  .few
    display: flex
    flex-direction: row
    flex-wrap: wrap
    @supports (display: grid)
      display: grid
      grid-column-gap: 24px
      grid-row-gap: 24px
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr))

  .asset
    margin-right: 0
    margin-bottom: 24px
    display: inline-block
    cursor: pointer
    width: 100%

  .content_asset_container .stat_header
    .star_icons
      display: inline-flex
    svg
      height: 32px
      width: 32px

  .assets
  .legend
    display: none

  @media only screen and (max-width: 1119px) and (min-width: 768px)
    .assets
      columns: 2

  @media only screen and (min-width: 1120px)
    .assets
      columns: 3

  @media only screen and (min-width: 1640px)
    .assets
      columns: 4

  @media screen and (max-width: 960px)
    .uerl_filters
      padding-left: 16px
      padding-right: 16px
      margin-top: 48px
    .asset_container
      padding-left: 24px
      padding-right: 24px
      overflow-x: hidden

  @media screen and (max-width: 720px)
    .filter
      width: 100% !important
      max-width: none !important

  @media screen and (max-width: 640px)
    .user-research-library-company-header
      display: flex
      margin-top: 64px
      height: 380px
      .header-text-container
        position: absolute
        left: 25%
        top: 20%
        transform: translate(0, -50%) !important
        transform: none
        margin: 0 auto
        text-align: center
        padding-left: 24px
        padding-right: 24px
        .logo
          margin: 0 auto
      h1, p
        text-align: center
    .asset_container
      padding-left: 16px
      padding-right: 16px
    .uerl_filters
      flex-direction: column
      margin: 48px auto 24px
      padding: 16px
      top: 64px

  @media screen and (max-width: 440px)
    .uerl_filters
      display: none

@media (max-width: 2000px)
  .header-text-container
    position: absolute
    max-width: 300px
    color: hsl(200, 24%, 100%)
    top: 220px
    left: 85px
    .uerc-text
      font-size: 30px
      font-weight: 500
      margin-top: 0.5rem
    .logo
      width: 100%
      max-width: 200px
      ::v-deep svg
        path
          fill: white
    .dark-text
      p, span
        color: black
      ::v-deep svg
        path
          fill: hsl(200, 8%, 8%)
    &--embedded
      top: 150px

@media (max-width: 1200px)
  .header-wrapper
    display: flex
    align-items: center
  .header-text-container
    position: relative
    top: unset
    left: unset

@media (max-width: 730px)
  .search-icon
    position: absolute
    z-index: 10
    margin-top: 16px
    margin-left: 1.3rem
    svg
      color: black
      width: 1rem
      height: 1rem
  .user-research-library-company-header
    .evi-dropdown
      width: 480px !important
  .search-wrapper
    width: 500px
  .search-box
    margin-left: 10px
    input
      width: 440px
</style>

<template>
  <div class="preview-content-wrapper">
    <div
      v-if="loading"
      class="loading-preview d-flex flex-column p-2 justify-content-center align-items-center"
    >
      <span class="mr-1">
        <EllipsisLoader />
      </span>
      <strong>Loading previews...</strong>
    </div>
    <div
      v-else-if="assets.length <= 0"
      class="preview d-flex flex-column p-2 justify-content-center align-items-center"
    >
      <span>Asset Preview</span>
      <div class="logo-wrapper border d-flex justify-content-center align-items-center mt-2">
        <Logo></Logo>
      </div>
    </div>
    <div v-else-if="assets.length > 0" class="d-flex flex-column p-2">
      <AssetPresenter
        v-for="asset in assets"
        :key="asset.id"
        :content_asset="asset.content_asset"
        :account="account"
        :crimp="false"
        class="mb-1"
      />
    </div>
  </div>
</template>

<script lang="ts">
import AssetPresenter from '@survey/components/AssetPresenter.vue'
import Logo from '@app/graphics/Logo.vue'
import EllipsisLoader from '@app/components/EllipsisLoader.vue'

export default {
  components: { Logo, AssetPresenter, EllipsisLoader },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    assets: {
      type: Array,
      required: true,
    },
    account: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="sass" scoped>

.preview-content-wrapper
  overflow: scroll
  -ms-overflow-style: none
  scrollbar-width: none
  height: 647px
  background: #f8f8f8
  width: 50%
  border-left: 1px solid #e0e0e0
  border-bottom-right-radius: 1rem
  .preview
    height: 100%
    font-weight: $headings-font-weight
    font-size: 0.7rem
  .logo-wrapper
    border-radius: 50%
    width: 70px
    height: 70px
    background: white
  .loading-preview
    height: 100%
    font-weight: $headings-font-weight
    font-size: 0.7rem
</style>
